<template>
  <div class="ec-container">
    <div class="ec-container px-160 txt-grey-900 pb-8">
      <BreadcrumbCpn :breadcrumb="breadcrumb" />

      <div class="row">
        <div class="col-md-8">
          <div class="bg-white p-2 radius-10 h-full">
            <div
              class="flex items-center justify-between fs-20 fw-500 border-b border-solid border-t-0 border-r-0 border-l-0 border-gray-200"
            >
              <div class="inline-flex">
                <!-- <b-form-checkbox
                  v-if="$route.name === 'ShoppingCart'"
                  v-model="areAllProductsChecked"
                  @change="checkAllProducts"
                  class="my-auto mr-2"
                  style="margin-left: 8px; transform: translateY(5px)"
                ></b-form-checkbox>-->

                <div
                  class="border-b-4 border-solid border-t-0 border-r-0 border-l-0 border-pri-900"
                >
                  <span class="txt-grey-900">
                    {{
                    $t('shopping_cart.lbl_product_uppercase')
                    }}
                  </span>
                  <span
                    class="txt-pri ml-2"
                  >{{ $shoppingCart ? `(${$shoppingCart.length})` : '(0)' }}</span>
                </div>
              </div>
              <div v-if="$shoppingCart.length">
                <div
                  class="flex items-center justify-end text-gray-400 hover:text-red-500 transition-all duration-300 cursor-pointer"
                  @click="appUtils.removeAllCartData()"
                >
                  <div class="mr-1 leading-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.8499 9.14062L18.1999 19.2106C18.0899 20.7806 17.9999 22.0006 15.2099 22.0006H8.7899C5.9999 22.0006 5.9099 20.7806 5.7999 19.2106L5.1499 9.14062"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3301 16.5H13.6601"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 12.5H14.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <p
                    class="fs-16 fw-400 leading-none mb-0"
                    style="transform: translateY(2px)"
                  >{{ $t('shopping_cart.lbl_remove_all') }}</p>
                </div>
              </div>
            </div>

            <ShoppingCartItem v-if="$shoppingCart.length" @checkProduct="checkSingleProduct" />
            <div v-else class="ec-body-list d-flex justify-center h-full">
              <div class="my-auto text-center py-3">
                <div class="inline-block mb-3 w-32 h-32">
                  <img src="../../../public/images/Page/empty.png" class="img-fluid mb-3" alt />
                </div>
                <div class="text-center fs-20">
                  <span>{{ $t('shopping_cart.lbl_empty_cart') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="bg-white p-2 radius-10 mb-3">
            <OrderAmount>
              <div class="flex justify-content-end">
                <button
                  class="w-100 btn bg-pri text-white"
                  @click="handleConfirmPay"
                >{{ $t('shopping_cart.lbl_billing_confirmation') }}</button>
              </div>
            </OrderAmount>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { v4 as uuidv4 } from 'uuid'
import appUtils from '../../utils/appUtils'
import ShoppingCartItem from '../../components/Ecom/ShoppingCartItem.vue'
import BreadcrumbCpn from '../../components/Layout/BreadcrumbCpn.vue'
import OrderAmount from '../../components/Ecom/OrderAmount.vue'
// import { ORDERS_STATUS } from '../../utils/constant'

export default {
  name: 'ShoppingCart',
  components: { ShoppingCartItem, BreadcrumbCpn, OrderAmount },
  data() {
    return {
      appUtils,
      areAllProductsChecked: false,
      breadcrumb: [
        {
          text: 'Quay lại',
          to: {
            name: 'Marketplace'
          }
        },
        {
          text: 'Giỏ hàng'
        }
      ]
    }
  },
  computed: {
    amount() {
      return this.$shoppingCart.reduce(
        (accum, item) => accum + item.default_price * item.count,
        0
      )
    }
  },
  methods: {
    checkSingleProduct() {
      console.log(this.$shoppingCart.every(item => item.isChecked))
      if (this.$shoppingCart.every(item => item.isChecked))
        this.areAllProductsChecked = true
      else this.areAllProductsChecked = false
    },
    checkAllProducts(checked) {
      this.$shoppingCart.forEach(item => {
        item.isChecked = checked
      })
    },
    async handleConfirmPay() {
      // TODO
      // if (!this.$user) {
      //   this.$targetRoute = '/#/shopping-cart'
      //   this.$router.push({ name: 'Login' })
      //   return false
      // }

      this.$router.push({
        name: 'OrderConfirm'
        // params: {
        //   listProduct: this.$shoppingCart.filter((elm) => elm.isChecked)
        // }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ec-container {
    background: rgba(247, 247, 249, 1);
    padding-top: 32px;
  }
  .btn-apply-vc {
    background: rgba(238, 97, 63, 1);
    width: 110px;
    border-radius: 4px;
    padding: 8px;
    border: 0;
    color: white;
    &:disabled {
      background: rgba(238, 97, 63, 0.5);
    }
  }
  .min-h-96 {
    min-height: 24rem /* 384px */;
  }
}
.bg-pri {
  background: #20419b;
}
</style>
